import axios from "axios";
import React, { useEffect } from "react"
import { useState } from "react";
import { Helmet } from 'react-helmet'
import styles from './home.module.css'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import { ReactComponent as Logo } from '../assets/hyve_logo_solid_red.svg'

function Success() {
  const [event, setEvent] = useState(null);
  const [idap, setIdap] = useState(null);
  const [overlay, setOverlay] = useState(false)

  const callFlow = () => {
    const query = window.location.search.substring(1);
    setIdap(query.split('=')[1]); // TODO: I set idap but I cannot use it anywhere here. Somehow it is never set to a value when it is called.

    // TODO: Make an improvement so that refreshing the page will not call the flow again -- avoiding duplicate records.
    // TODO: Another issue is that, azios post call is tried when idap is not ready yet. (This causes 502 (Bad Gateway).) It shouldn't be.
    setOverlay(true)
    axios.post(window.UPDATE_FLOW_URL,
      {
        "TipaltiID": window.location.search.substring(1).split('=')[1],
        "env": window.HYVE_ENV,
      })
      .then(res => {
        setEvent(res.data.event);
      })
      .catch(function (error) {
        console.log(error);
      }).finally(() => {
        setOverlay(false)
      })
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // if idap is not provided, then skip making a call to the flow
    if (urlParams.get('idap') == null)
      return

    callFlow();
  }, [])

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={overlay}
      ><CircularProgress color="inherit" />
      </Backdrop>
      <div className={styles['container']}>
          <header data-role="Header" className={styles['Header']}>
            <Box className={styles['logo']} sx={{cursor: 'pointer'}}>
              <Logo className={styles['image']} onClick={() => window.location = window.origin} />
            </Box>
          </header>
          <Helmet>
            <title>Hyve Reimbursement Form</title>
            <meta property="og:title" content="Hyve Reimbursement Form" />
          </Helmet>
          <h1 className={styles['text1']}>Thank you for your claim!</h1>
          <div className={styles['success-content']}>
            <p>
              Your claim has been successfully submitted for review, your claim reference is <b>{idap}</b>!<br/>
              Any queries relating to this claim should be emailed to <b>{event?.email}</b> quoting your claim reference.<br/>
              Please allow <strong>{event?.claimDays || 30} days</strong> for Hyve to process this payment.*<br/>
            </p>
            <p>
              *Payments will be delayed if banking information is missing or incorrect<br/>
              The claimant accepts full responsibility for any tax reporting and payment obligations that may arise from this claim.<br/>
            </p>
          </div>
      </div>
    </>
  )
}

export default Success