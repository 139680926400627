let events_ = null;

export const currencyMap = {
    'GBP': '£',
    'USD': '$',
    'EUR': '€',
}

export function getEvent(id) {
    establishEventList();
    return events_.filter(e => {
        return e.id == id
    })[0];
}

export function getEventList(onlyActive = true) {
    establishEventList();
    return events_.filter(e => {
        return !onlyActive || e?.active || false
    });
}

function establishEventList() {
    try {
        if (!!events_)
            return;
        const config = require('./config.json');
        events_ = JSON.parse(config[`events-${window.HYVE_ENV}.json`]).events;
        events_.forEach(e => {
            e.notes = config[`event-notes-${e.id}.txt`]
                ?? config['event-notes-default.txt']
            e.notes = e.notes?.trim()
                .replace("%name%", e.name)
                .replace("%email%", e.email)
                .replace("%currency%", e.currency)
                .replace("%currency-symbol%", currencyMap[e.currency] || '???')
                ?? "";
            let lines = e.notes.split("\n")
            e.notes = lines.map(l => `<li>${l}</li>`).join('')
        });
        return events_.sort((a, b) => {
            return a.name.localeCompare(b.name)
        });
    } catch {
        events_ = []
    }
}
