import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import './style.module.css'
import Home from './views/home'
import Success from './views/success'

const App = () => {
  return (
    window.TIPALTI_KEY == null ? `Missing config-${process.env.NODE_ENV}.js file` :
    <CookiesProvider>
      <Router>
        <div>
          <Route exact component={Home} path="/" />
          <Route exact component={Success} path="/success" />
        </div>
      </Router>
    </CookiesProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
