import React, { useState, useEffect } from "react"
import axios from 'axios'
import ShortUniqueId from 'short-unique-id'
import CryptoJS from 'crypto-js'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { Select, InputLabel, MenuItem, FormControl,
	TextField, Box, Chip, Button, Tooltip, Backdrop,
	CircularProgress, Dialog, DialogContent, DialogActions } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import {isEmail, isCurrency} from 'validator'
import countries from '../countries'
import styles from './home.module.css'
import { useCookies } from 'react-cookie'
import { ReactComponent as Logo } from '../assets/hyve_logo_solid_red.svg'
import { currencyMap, getEventList } from '../config'

let tipaltiKey = ''
let tipaltiSubmitUrl = ''

function EventNotes({html}) {
	return <em>
		<ul dangerouslySetInnerHTML={{__html: html}} />
	</em>
}

const Home = () => {
	const [paymentTermsMsg, setPaymenTermsMsg] = useState();
	const [langPopupOpen, setLangPopupOpen] = useState(false);
	const [timestamp, setTimestamp] = useState("");
	const [uid, setUid] = useState("");
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [url, setUrl] = useState("");
	const [message, setMessage] = useState("");
	const [redirectto, setRedirectto] = useState("");
	const [hash, setHash] = useState("");
	const [fName, setFName] = useState("");
	const [lName, setLName] = useState("");
	const [country, setCountry] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [amount, setAmount] = useState('');
	const [amountHelperText, setAmountHelperText] = useState('')
	const [submitted, setSubmitted] = useState(false);
	const [eventId, setEventId] = useState("");
	const [eventObj, setEventObj] = useState(null);
	const [maxAmount, setMaxAmount] = useState("");
	const [maxAmountStr, setMaxAmountStr] = useState("");
	const [isReadOnly, setIsReadOnly] = useState({
		"fname": false,
		"lname": false,
		"company": false,
		"email": false,
		"event": false,
		"country": false
	});
	const [countriesList, setCountriesList] = useState([])
	const [data, setData] = useState(null)

	const [overlay, setOverlay] = useState(false)

	const [cookies, setCookie] = useCookies(['eventId']);

	const setEventCookie = (eventId) => {
		const date = new Date();
		date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
		setCookie('eventId', eventId, {expires: date})
	}

	const uidGen = new ShortUniqueId({ length: 16 });
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);

	const setUrlVar = () => {
		tipaltiKey = window.TIPALTI_KEY
		tipaltiSubmitUrl = window.TIPALTI_FORM_SUBMIT_URL
		setRedirectto(`${window.location.origin}/success`)
	}

	const setQueryString = () => {
		if (queryString != '') {

			let readOnlyOrNot = {}

			const checkParam = (value) => {
				if (urlParams.get(value) != null) {
					readOnlyOrNot[value] = true
					switch (value) {
						case "fname":
							setFName(urlParams.get(value));
							break;
						case "lname":
							setLName(urlParams.get(value));
							break;
						case "email":
							setEmail(urlParams.get(value));
							break;
						case "company":
							setCompany(urlParams.get(value));
							break;
						case "event":
							setEventId(urlParams.get(value));
							break;
						case "country":
							setCountry(urlParams.get(value));
							break;
					}
				} else {
					readOnlyOrNot[value] = false
				}
			}
			checkParam("fname");
			checkParam("lname");
			checkParam("company");
			checkParam("event");
			checkParam("email");
			checkParam("country");
			setIsReadOnly(readOnlyOrNot);
		}
	}

	useEffect(() => {
		setUrlVar();
		setQueryString();
		setUid(uidGen());
		setData(getEventList(true));
  	}, []);

	// For characters encodeURIComponent doesn't apply encoding, that's it returns the same character.
	// . - ! ' ( ) * -
	const encodeExpanded = (str) => {
		str = encodeURIComponent(str);
		str = str.replace(/\-/g, '%2D')
			.replace(/\_/g, '%5F')
			.replace(/\./g, '%2E')
			.replace(/\!/g, '%21')
			.replace(/\~/g, '%7E')
			.replace(/\*/g, '%2A')
			.replace(/\'/g, '%27')
			.replace(/\(/g, '%28')
			.replace(/\)/g, '%29');
		return str;
	}

	useEffect(() => {
		const pes = {
			h: 'HyveGroup',
			r: 'RegentUS', 
		}
		const pe = pes[eventObj?.tipaltiPayerEntity]
			|| pes['h']
		setMessage([
			'payer=HyveGroup',
			`preferredPayerEntity=${pe}`,
			`ts=${timestamp}`,
			`idap=${uid}`,
			`country=${encodeExpanded(country)}`,
			`email=${email}`,
			`first=${encodeExpanded(fName)}`,
			`last=${encodeExpanded(lName)}`,
			`company=${encodeURIComponent(company)}`,
			`redirectto=${redirectto}?idap=${uid}`,
		].join('&'))
	}, [timestamp]);

	useEffect(() => {
		setHash(CryptoJS.HmacSHA256(message, tipaltiKey));
	}, [message]);

	useEffect(() => {
		setUrl(tipaltiSubmitUrl + message + "&hashkey=" + hash);
		// eslint-disable-next-line
	}, [hash]);

	const isAmountValid = (amount) => {
		return !!eventObj
			&& isCurrency(amount)
			&& eventObj.claimMax
			&& parseFloat(amount)
				<= eventObj.claimMax;
	}

	useEffect(() => {
		if (!eventObj
			|| !amount.length
			|| isAmountValid(amount))
			setAmountHelperText('')
		else if (!isCurrency(amount))
			setAmountHelperText(`Must be a valid amount`)
		else if (eventObj.claimMax)
			setAmountHelperText(`Must be valid and no more than ${eventObj.currency} ${eventObj.claimMax}`)
	}, [amount])

	useEffect(() => {
		if (!!data && !!eventId) {
			const ev = data.find(e => e.id == eventId);
			setEventObj(ev)
			if (!ev.claimMax) ev.claimMax = 99999999
			setEventCookie(ev.id);
			setMaxAmount(ev.claimMax);
			setMaxAmountStr(ev.currency + ev.claimMax);

			if (parseFloat(amount) > ev.claimMax) {
				alert(`Maximum allowed amount is ${ev.claimMax}.`);
				setAmount(amount);
			}

			const tmp = []
			countries.forEach((c) => {
				tmp.push(c)
				const ix = ev.preferredCountries?.findIndex((pc) => {
					return pc == c.code
				}) || -1
				if (ix != -1) tmp.splice(ix, 0, c)
			})
			setCountriesList(tmp)
		}
		// eslint-disable-next-line
	}, [eventId])

	const handleFileinput = (e) => {
		e.preventDefault();
		if (e.target.files.length == 0) return;

		const file = e.target.files[0]
		var allowedExtensions = /(\.jpg|\.pdf|\.jpeg|\.png)$/i;
		if (!allowedExtensions.exec(file.name.toLowerCase())) {
			alert('Incorrect file type selected. Please select only pdf, jpg or jpeg files.');
			e.target.value = "";
			return
		}

		// check if file has been uploaded and update
		// or write a new file to the state
		let reader = new FileReader();
		reader.readAsDataURL(file); 
		const index = selectedFiles.findIndex(sf => {
			return sf.name.toLowerCase() === file.name.toLowerCase();
		});
		reader.onload = () => {
			const newSf = {
				"name": file.name,
				"file": reader.result.split('base64,').pop()
			}
			const newSelectedFiles = [...selectedFiles]
			if (index == -1) {
				newSelectedFiles.push(newSf)
			} else {
				newSelectedFiles.splice(index, 1, newSf)
			}
			setSelectedFiles(newSelectedFiles)
		}
	}

	// See 'Country' parameter at:-
	// https://support.tipalti.com/Content/Topics/Development/iFrames/IframeRequestStructure.htm

	const companyNameValid = () => {
		return /^[a-zA-Z0-9 \.\-]*$/.test(company)
	}

	// See 'Name' parameter at:-
	// https://support.tipalti.com/Content/Topics/Development/iFrames/IframeRequestStructure.htm

	const nameValid = (name) => {
		return /^[a-zA-Z0-9 ]*$/.test(name)
	}

	const submitForm = e => {
		e.preventDefault();

		const companyValidation = () => {
			if (company != "") { return true } else {
				alert("Please enter Company.")
				return false
			}
		}

		const nameValidation = () => {
			if (fName != "" && lName != "") { return true } else {
				alert("Please enter your First and Last Name.")
				return false
			}
		}

		const amountValidation = () => {
			if (amount == "" || amount <= 0.00) {
				alert("Please enter the claim Amount.")
				return false
			}
			else {
				if (parseFloat(amount) > parseFloat(maxAmount)) {
					alert(`Maximum allowed amount is ${maxAmount}.`);
					return false
				}
				return true
			}
		}

		const eventValidation = () => {
			if (eventId != "") { return true } else {
				alert("Please select an Event.")
				return false
			}
		}

		const countryValidation = () => {
			if (country != "") { return true } else {
				alert("Please select your Country.")
				return false
			}
		}

		const charValidationName = (name) => {
			if (!nameValid(name)) {
				alert("Please only use a-z, A-Z, 0-9, space in the name fields.")
				return false
			} else {
				return true
			}
		}

        const charValidationCompany = () => {
			if (!companyNameValid(company)) {
				alert('Company name not valid')
				return false
			}
			else return true
        }
		
		setTimestamp(Math.round(Date.now() / 1000));

		let body = {
			"userId": uid,
			"fName": fName,
			"lName": lName,
			"company": company,
			"event": eventObj.id,
			"country": country,
			"amount": amount,
			"email": email,
			"files": selectedFiles,
			"env": window.HYVE_ENV,
		}

		if (nameValidation() == true
			&& charValidationName(fName) == true 
			&& charValidationName(lName) == true			
			&& companyValidation() == true 
			&& charValidationCompany() == true
			&& emailValidation() == true 
			&& eventValidation() == true 
			&& countryValidation() == true 
			&& amountValidation() == true 
			&& fileValidation() == true) {
			setOverlay(true)
			axios.post(window.CREATE_FLOW_URL, body)
			.then((res) => {
				return setSubmitted(true)
			}).catch((err) => {
				alert("File Upload Error - please refresh the page and try again.")
			}).finally(() => {
				setOverlay(false)
			})
			window.scrollTo(0, 0)
		}
		window.scrollTo(0, 0);
	};

	const emailValidation = () => {
		if (!email || !isEmail(email)) {
			alert("Email format is not valid.")
			return false;
		}
		return true;
	}

	const IFrame = () => {
		return <>
			<span className={styles['text1']}> Your session will timeout in <Countdown date={(timestamp * 1000) + 600000} /> </span>
			<iframe className="iFrame" name="BankInfo" src={url} title="BankInfo" style={{
				"border": "0",
				"margin": "0",
				"width": "100%",
				"height": "190vh",
				"overflow": "hidden",
				"borderWidth": "0 !important"
			}}></iframe>
		</>;
	};

	const fileValidation = () => {
		if (!selectedFiles.length) {
			alert("Please upload at least 1 file before submitting.")
			return false
		} else {
			return true
		}
	}

	const onKeyDownForEmail = (e) => {
		if ((e.key >= "a" && e.key <= "z")
			|| (e.key >= "A" && e.key <= "Z")
			|| (e.key >= "0" && e.key <= "9")
			|| e.key === "_"
			|| e.key === "-"
			|| e.key === "."
			|| e.key === "@") {
		} else {
			e.preventDefault();
		}
	}

	const chipOnDelete = (index) => {
		const tmp = [...selectedFiles]
		tmp.splice(index, 1)
		setSelectedFiles(tmp)
	}

	const createPaymentTermsMsg = (countryCode) => {
		let type = eventObj?.paymentInfoPopup?.[countryCode];
		if (!type)
			type = eventObj?.paymentInfoPopup?.["default"];
		let typeMsgs = {
			"a": "ACH payment",
			"w": "Wire Transfer"
		}
		let typeMsg = typeMsgs?.[type];
		return typeMsg
			&& `Please select <strong>${typeMsg}</strong> method on the Payment Method page (section 2) to avoid delays to your reimbursement`
			|| null
	}

	return (
		<div className={styles['container']}>
			<Dialog open={langPopupOpen} onClose={() => {setLangPopupOpen(false)}}>
				<DialogContent>
					<Box dangerouslySetInnerHTML={{__html: paymentTermsMsg}} />
				</DialogContent>
				<DialogActions>
          			<Button onClick={() => setLangPopupOpen(false)}>Got it</Button>
		        </DialogActions>
			</Dialog>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={overlay}
			><CircularProgress color="inherit" />
			</Backdrop>
			<Helmet>
				<title>Hyve Reimbursment Form</title>
				<meta property="og:title" content="Hyve Reimbursment Form" />
			</Helmet>
			<header data-role="Header" className={styles['Header']}>
				<div className={styles['logo']}>
					<Logo className={styles['image']} />
				</div>
			</header>
			{submitted ? <IFrame /> :
				<div className={styles['Hero']}>
					<div className={styles['container1']}>
						<h1 className={styles['text1']}>Hyve Reimbursement Form<span className={styles['req']}>.</span></h1>
						<div className={styles['container1Event']}>
							<span>
								Please select your Event from the dropdown to begin the Reimbursement process:							
							</span>
							<div>
								<FormControl
									sx={{ml: 1}}
								>
									<InputLabel id="event-id-label">Event</InputLabel>
									<Select
										id="event-id"
										labelId="event-id-label"
										label="Event"
										value={eventObj?.id || ''}
										onChange={e => {
											setEventId(e.target.value);
											setCountry("");
										}}
										sx={{
											width: '20em',
										}}
									>
									{data?.map((item) => (
										<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
									))}
									</Select>
								</FormControl>
							</div>
						</div>
						{eventObj ? <div className={styles['container1Desc']}>
							<span className={'text'}>
								<p>Please follow these steps:</p>
								<ol>
									<li>Complete all mandatory fields on the right</li>
									<li>Upload a minimum of 1 and a maximum of 15 receipt files in jpg, jpeg or pdf format for <strong>{eventObj.name}</strong>. If you have more receipts to submit, please contact <strong>{eventObj.email}</strong>.</li>
									<li>Before pressing <strong>'Continue'</strong> please have your <strong>mobile phone</strong> ready to set up 2-step verification for our payment provider and your <strong>bank account</strong> details.</li>
									<li>After completing the 2-step verification you will need to provide your contact information and confirm the bank account details for reimbursement (this can either be a personal or business account).</li>
									<li>You will receive an email confirmation with a payment reference number shortly after uploading your receipts.</li>
								</ol>
							</span>
							<div>
								<div>Please note:</div>
								<EventNotes html={eventObj.notes}/>
							</div>
						</div> : ''}
					</div>
					<div className={styles['container2']}>
						<form className={styles['form']}>
							<Box sx={{my: 2}}>
								<TextField
									disabled={!eventId}
									fullWidth 
									variant="outlined"
									placeholder="Please enter your First Name"
									label="First Name"
									onChange={(e) => {
										setFName(e.target.value)
									}}
									required
									inputProps={{
										maxLength: 70
									}}
									error={!nameValid(fName)}
									helperText={!nameValid(fName)
										? 'Must only contain a-z, A-Z, 0-9, space'
										: ''}
								/>
							</Box>
							<Box sx={{my: 2}}>
								<TextField
									disabled={!eventId}
									fullWidth 
									variant="outlined"
									placeholder="Please enter your Last Name"
									label="Last Name"
									onChange={(e) => {
										setLName(e.target.value)
									}}
									required
									inputProps={{
										maxLength: 70
									}}
									error={!nameValid(lName)}
									helperText={!nameValid(lName)
										? 'Must only contain a-z, A-Z, 0-9, space'
										: ''}
								/>
							</Box>
							<Box sx={{my: 2}}>
								<TextField
									disabled={!eventId}
									fullWidth 
									variant="outlined"
									placeholder="Please enter your Company"
									label="Company"
									onChange={(e) => {
										setCompany(e.target.value)
									}}
									required
									inputProps={{
										maxLength: 70
									}}
									error={!companyNameValid(company)}
									helperText={!companyNameValid(company)
										? 'Must only contain a-z, A-Z, 0-9, space, dot (.), hyphen (-)'
										: ''}
								/>
							</Box>
							<Box sx={{my: 2}}>
								<TextField
									disabled={!eventId}
									fullWidth 
									variant="outlined"
									placeholder="Please enter your Email"
									label="Email"
									onChange={(e) => {
										setEmail(e.target.value)
									}}
									required
									inputProps={{
										type: 'email',
									}}
									error={email?.length && !isEmail(email) ? true : false}
									helperText={email?.length && !isEmail(email)
										? 'Enter a valid email address'
										: ''}
								/>
							</Box>
							<Box sx={{my: 2}}>
								<FormControl fullWidth>
									<InputLabel id="country-id-label">Country</InputLabel>
									<Select
										value={country}
										disabled={!eventId}
										fullWidth
										id="country-id"
										labelId="country-id-label"
										label="Country"
										onChange={e => {
											setCountry(e.target.value);
											const msg = createPaymentTermsMsg(e.target.value);
											setPaymenTermsMsg(msg);
											if (!!msg)
												setLangPopupOpen(true);
										}}
									>
									{countriesList.map((c, i) => (
										<MenuItem key={i} value={c.code}>{c.name}</MenuItem>
									))}
									</Select>
								</FormControl>
							</Box>
							<Box sx={{my: 2}}>
								<TextField
									disabled={!eventId}
									fullWidth 
									variant="outlined"
									placeholder="Please enter the Amount"
									label="Amount"
									onChange={(e) => setAmount(e.target.value)}
									required
									error={amount?.length && !isAmountValid(amount) ? true : false}
									helperText={amountHelperText}
									InputProps={{
										startAdornment: <InputAdornment position="start">{currencyMap[eventObj?.currency] || ''}</InputAdornment>,
									}}
								/>
							</Box>
							<Box sx={{my: 2}}>
								<label>Receipt(s)</label>
							</Box>
							<Box sx={{my: 2}}>
							{selectedFiles?.map((v, i) => {
								return v.name?.length ?
								<Tooltip
									key={v.name}
									title={v.name}
									placement="top"
									enterDelay={700}
								>
								<Chip
									sx={{my: 1, width: '100%'}}
									disabled={!eventId}
									label={v.name}
									color="primary"
									onDelete={() => chipOnDelete(i)}
								/>
								</Tooltip>
								: ''
							})}
							</Box>
							<Button
								disabled={!eventId}
								sx={{my:1}}
								fullWidth
								variant="contained"
								component="label"
							>
								Add Receipt
								<input
									type="file"
									hidden
									accept="image/*;application/pdf"
									onChange={handleFileinput}
								/>
      						</Button>
							<Button
								disabled={!eventId}
								sx={{my:1}}
								fullWidth
								variant="contained"
								onClick={submitForm}
								color="success"
							>Continue</Button>
						</form>
					</div>
				</div>}
		</div>
	)
}

export default Home